import { get_theme } from '../API/index';

class ThemeService {
	setThemeInLocalStorage(data) {
		localStorage.setItem('theme', JSON.stringify(data));
	}

	async getTheme() {
		const response = await get_theme();

		if (response.status !== 200) {
			return null;	
		}

		this.setThemeInLocalStorage(response.data.theme);
		return response.data.theme;
	}

	getCurrentTheme() {
		const theme = localStorage.getItem('theme');
		if (!theme || theme == 'undefined') {
			return null;
		}

		return JSON.parse(theme);
	}
}

export default new ThemeService();
