import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './index.css';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import { getMatches, isValid } from 'driver-license-validator';
import toast from 'react-hot-toast';
import moment from 'moment';
import { get_all_airlines, get_car_by_id } from '../../API/index';
import { get_system_configurations } from '../../API/system_configurations';
import { AuthContext } from '../../contexts/AuthContext';
import ErrorModal from '../ErrorModal';
import { format } from 'date-fns';
import { get_coupon } from '../../API/coupon';
import { get_discount_price } from '../../API/car_booking';
import { getCarName } from '../../helpers/carName';
// import { ChatLoader } from '../ChatLoader';
import { CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { format_date_time } from '../../helpers/formatDatTime';

export default function PaymentDetails({ ...prop }) {
  const props = prop.props;
  // const carData = prop.carData;
  const { id } = useParams();
  const navigate = useNavigate();
  const today = format(new Date(), 'yyyy-MM-dd');

  const [carDetail, setCarDetail] = useState(null);
  const [carCurrentPrice, setCarCurrentPrice] = useState(
    carDetail?.daily_price
  );
  const [carCheckoutPrice, setCarCheckoutPrice] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [totalBookingAmount, setTotalBookingAmount] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [youngRenterFee, setYoungRenterFee] = useState(null);

  const [verifyDriverLicenseNo, setVerifyDriverLicenseNo] = useState(null);
  const [infoModal, setInfoModal] = useState(false);
  const [insuranceErrorModal, setInsuranceErrorModal] = useState(false);
  const [ageModal, setAgeModal] = useState(false);
  const [airlines, setAirlines] = useState([]);
  const [systemConfigurations, setSystemConfigurations] = useState(null);
  const { user, authAxios } = useContext(AuthContext);

  const handleDriverLicenseVerify = (e) => {
    e.preventDefault();
    setVerifyDriverLicenseNo(isValid(props.driverLicenseNo));
  };

  const format_date_time = (date, time) => {
    return date && format(date, 'yyyy-MM-dd') + 'T' + time;
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (props.age < 25) {
      setAgeModal(true);
      return;
    }

    if (!moment(props.untilDate).isBefore(moment(props.insuranceExpDate))) {
      setInsuranceErrorModal(true);
      return;
    }

    // if (!props.driverLicenceImg) {
    //   ErrorModal('Select the Driver License Image');
    //   return;
    // }

    navigate(`/payment/${id}`);
  };

  useEffect(() => {
    get_car_by_id(
      id,
      format_date_time(props.fromDate, props.fromTime),
      format_date_time(props.untilDate, props.untilTime)
    ).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        setCarDetail(res.data);
      } else {
        console.log(res);
      }
    });
    get_all_airlines().then((res) => {
      if (res.status == 200) {
        setAirlines(res.data);
      } else {
        ErrorModal(res.response.data.message);
      }
    });
    get_system_configurations(authAxios).then((res) => {
      setSystemConfigurations(res.data[0]);
    });
  }, []);

  useEffect(() => {
    const getCarPrice = () => {
      const basePrice = carDetail?.daily_price;
      return carDetail?.custom_prices.length > 0
        ? carDetail?.custom_prices.every((customPrice) => {
            if (
              today >= customPrice.start_date &&
              today <= customPrice.end_date
            ) {
              setCarCurrentPrice(Number(customPrice.price));
              return false;
            } else {
              setCarCurrentPrice(Number(basePrice));
              return true;
            }
          })
        : setCarCurrentPrice(Number(basePrice));
    };

    getCarPrice();
  }, [carDetail]);

  useEffect(() => {
    const carUpdatedPrice =
      carCurrentPrice && carCurrentPrice * props.bookingPeriod;
    setCarCheckoutPrice(carUpdatedPrice);
  }, [carCurrentPrice, props.bookingPeriod, props.fromDate, props.untilDate]);

  const numtoFixed = (num) => (Math.round(num * 100) / 100).toFixed(2);
  const total_price =
    props.age < 25
      ? Number(
          totalBookingAmount *
            (1 + (systemConfigurations?.young_renter_fee / 100 || 0))
        )?.toFixed(2)
      : totalBookingAmount?.toFixed(2);

  const handleCoupon = (e) => {
    e.preventDefault();
    get_coupon(discountCode.trim()).then((res) => {
      if (res.status === 200) {
        toast.success('Coupon Found');
        setCoupon(res.data);
      } else {
        toast.error('Not Found Coupon');
      }
    });
  };

  const taxAmount =
    (systemConfigurations?.tax ?? 0) *
    ((discountedPrice ?? carCheckoutPrice) + Number(props.deliveryCharge));

  useEffect(() => {
    if (props.age) {
      const young_renter_fee =
        (systemConfigurations?.young_renter_fee / 100 ?? 0) *
        ((discountedPrice ?? carCheckoutPrice) + Number(props.deliveryCharge));
      setYoungRenterFee(young_renter_fee);
    }
  }, [
    systemConfigurations,
    discountedPrice,
    carCheckoutPrice,
    props.deliveryCharge,
    props.age,
  ]);

  useEffect(() => {
    const discount_data = {
      car_id: id,
      start_date: format_date_time(props.fromDate, props.fromTime),
      end_date: format_date_time(props.untilDate, props.untilTime),
      // start_date: format(props.fromDate || new Date(),"yyyy-MM-dd'T'HH:mm"),
      // end_date: format(props.untilDate || new Date(), "yyyy-MM-dd'T'HH:mm"),
    };
    systemConfigurations &&
      get_discount_price(authAxios, discount_data)
        .then((res) => {
          if (res.status == 200) {
            const coupon_amount =
              (coupon?.amount_type != 'A'
                ? (props.bookingPeriod *
                    carDetail?.daily_price *
                    Math.abs(coupon?.amount)) /
                  100
                : Math.abs(coupon?.amount)) || 0;
            setDiscountPrice(res.data.discount + coupon_amount);
            setDiscountedPrice(
              (carCheckoutPrice ?? res.data.total_price) -
                (res.data.discount + coupon_amount)
            );
            setCarCheckoutPrice(res.data.total_price);
          } else {
            ErrorModal(res.response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
  }, [systemConfigurations, props.bookingPeriod, coupon, carCheckoutPrice]);

  useEffect(() => {
    const bookingAmountTotal =
      (discountedPrice ?? carCheckoutPrice) +
      Number(props.deliveryCharge) +
      taxAmount +
      (props.age < 25 && youngRenterFee ? youngRenterFee : 0);

    setTotalBookingAmount(bookingAmountTotal);
  }, [
    carCheckoutPrice,
    discountedPrice,
    taxAmount,
    youngRenterFee,
    props.bookingPeriod,
    props.deliveryCharge,
    props.fromDate,
    props.untilDate,
    props.age,
  ]);
  console.log(props.insurancePolicyNo);

  return (
    <div className='paymentDetails_wrapper userInfoWrapper'>
      <div className='paymentDetails'>
        <div
          className='go_back'
          onClick={() => {
            navigate(-1);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            viewBox='0 0 20 20'
            fill='#46a8c8'
            width={30}
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='paymentDetails_heading'>Renter Information</div>
        <form
          action='#'
          onSubmit={(e) => handleSave(e)}
          className='card_details'
        >
          <div className='info_wrapper'>
            <div className='info_row'>
              <FormControl sx={{ width: '100%' }}>
                First Name *
                <TextField
                  required
                  className='info_input'
                  id='outlined-basic'
                  label='Enter First Name'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.firstName}
                  onChange={(e) => props.setFirstName(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                Last Name *
                <TextField
                  required
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Last Name'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.lastName}
                  onChange={(e) => props.setLastName(e.target.value)}
                />
              </FormControl>
            </div>
            <div className='info_row'>
              <FormControl sx={{ width: '100%' }}>
                Email *
                <TextField
                  required
                  type='email'
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Email'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                Phone Number *
                <PhoneInput
                  label={'Phone No *'}
                  className={'phone'}
                  req={true}
                  helperText={''}
                  error={true}
                  isSelect={false}
                  specialLabel={''}
                  country={'us'}
                  value={props.mobileNo}
                  onChange={(value) => props.setMobileNo(value)}
                  variant='outlined'
                  // inputStyle={{
                  //   borderColor: "red"
                  // }}
                />
                {/* <TextField
                  type='number'
                  required
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Phone Number'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.mobileNo}
                  onChange={(e) => props.setMobileNo(e.target.value)}
                /> */}
              </FormControl>
            </div>
            <div className='info_row '>
              <FormControl sx={{ width: '100%' }}>
                Flight #
                <TextField
                  type='text'
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Flight #'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.flightNo}
                  onChange={(e) => props.setFlightNo(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                Airline Name
                <Select
                  className='airport_name'
                  labelId='airport_name'
                  id='demo-simple-select'
                  value={props.airlineName}
                  label='Enter Airline Name'
                  onChange={(e) => props.setAirlineName(e.target.value)}
                >
                  {airlines.map((airline) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={airline.name}
                      key={airline.id}
                    >
                      {airline.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='info_row '>
              <FormControl sx={{ width: '100%' }}>
                Driver License No.
                <TextField
                  type='text'
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Driver License No.'
                  variant='outlined'
                  value={props.driverLicenseNo}
                  onChange={(e) => (
                    props.setDriverLicenseNo(e.target.value),
                    setVerifyDriverLicenseNo(null)
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                Age *
                <TextField
                  required
                  type='text'
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Age'
                  variant='outlined'
                  value={props.age}
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  onChange={(e) => props.setAge(e.target.value)}
                />
              </FormControl>
            </div>
            <div className='info_row '>
              <Button
                required
                className='user_upload_btn'
                variant='contained'
                component='label'
              >
                <svg
                  width='36'
                  height='36'
                  viewBox='0 0 36 36'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                    fill='white'
                  />
                  <path
                    d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                    fill='white'
                  />
                </svg>
                {props.driverLicenceImg
                  ? props.driverLicenceImg.name
                  : 'Upload Attachments (Driver License)'}
                <input
                  type='file'
                  name='UserDriverLicense'
                  id='UserDriverLicense'
                  hidden
                  onChange={(e) => props.setDriverLicenceImg(e.target.files[0])}
                  novalidate
                />
              </Button>
            </div>
            <div className='info_row '>
              <FormControl sx={{ width: '100%' }}>
                Insurance Company *
                <TextField
                  required
                  type='text'
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Insurance Company'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.insuranceCompany}
                  onChange={(e) => props.setInsuranceCompany(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                Insurance Policy *
                <TextField
                  required
                  type='text'
                  className='info_input'
                  id='outlined-basic'
                  label='Enter Insurance Policy'
                  variant='outlined'
                  sx={{
                    '.MuiInputLabel-asterisk': {
                      display: 'none',
                    },
                  }}
                  value={props.insurancePolicyNo}
                  onChange={(e) => {
                    props.setInsurancePolicyNo(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className='info_row '>
              <div className='date_time'>
                <FormControl sx={{ width: '100%' }}>
                  Insurance Expiry Date *
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      // shouldDisableDate={(23, 24, 25) => true}
                      disablePast
                      label='Select Date'
                      value={props.insuranceExpDate}
                      onChange={(newValue) => {
                        // console.log(newValue, props);
                        props.setInsuranceExpDate(newValue);
                      }}
                      sx={{
                        '.MuiInputLabel-asterisk': {
                          display: 'none',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          className='time_date_picker'
                          size=''
                          required
                          // type='date'
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>
            </div>
            <div className='info_row '>
              <Button
                required
                className='user_upload_btn'
                variant='contained'
                component='label'
              >
                <svg
                  width='36'
                  height='36'
                  viewBox='0 0 36 36'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                    fill='white'
                  />
                  <path
                    d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                    fill='white'
                  />
                </svg>
                {props.InsuranceImg
                  ? props.InsuranceImg.name
                  : 'Upload Attachments (Insurance Card)'}
                <input
                  type='file'
                  name='UserDriverLicense'
                  id='UserDriverLicense'
                  hidden
                  onChange={(e) => props.setInsuranceImg(e.target.files[0])}
                  novalidate
                />
              </Button>
            </div>
          </div>
          <div className='confirmation_btn'>
            <button
              type='submit'
              // disabled={verifyDriverLicenseNo ? false : true}
            >
              {' '}
              Save{' '}
            </button>
          </div>
        </form>
      </div>
      <div style={{ paddingTop: '7.2rem' }} className='paymentDetails_card'>
        {(discountedPrice || carCheckoutPrice) &&
        totalBookingAmount &&
        (taxAmount || taxAmount === 0) ? (
          <>
            {/* <div className='totalPrice'> 
              Total Price: ${totalBookingAmount?.toFixed(2)}
            </div> */}
            <div className='paymentDetails_reviewCard'>
              <div className='paymentDetails_reviewCard_Header'>
                <img
                  src={carDetail?.car_images[0]?.image}
                  alt={carDetail?.make}
                />
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {getCarName(carDetail)}
              </div>

              <div className='bookingTimings'>
                <div className='bookingFromDate'>
                  <div>
                    {format(
                      new Date(
                        format_date_time(props.fromDate, props.fromTime)
                      ),
                      'EEEE, MMM dd'
                    )}
                  </div>
                  <div>
                    {format(
                      new Date(
                        format_date_time(props.fromDate, props.fromTime)
                      ),
                      "hh:mm aaaaa'm'"
                    )}
                  </div>
                </div>
                <div className='bookingFromDate'>
                  <div>
                    {format(
                      new Date(
                        format_date_time(props.untilDate, props.untilTime)
                      ),
                      'EEEE, MMM dd'
                    )}
                  </div>
                  <div>
                    {format(
                      new Date(
                        format_date_time(props.untilDate, props.untilTime)
                      ),
                      "hh:mm aaaaa'm'"
                    )}
                  </div>
                </div>
              </div>
              <div className='bookingDiliveryDetail'>
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  Delivery Location
                  <div className='bookingAirport'>{props.airportName}</div>
                </div>
              </div>
              <div className='bookingPaymentDetail'>
                <div className='conf_detail'>
                  <div className='conf_detail_header'>Payment Detail</div>
                  <div className='payment_row'>
                    <span>
                      ${numtoFixed(carCheckoutPrice / props?.bookingPeriod)} x{' '}
                      {props?.bookingPeriod} days
                      {/* ${carDetail?.daily_price} * {props?.bookingPeriod} days */}
                    </span>
                    <span>$ {numtoFixed(carCheckoutPrice)}</span>
                  </div>
                  <div className='payment_row'>
                    <span>Discount</span>
                    <span style={{ color: 'var(--light-blue)' }}>
                      - $ {numtoFixed(discountPrice)}
                    </span>
                  </div>
                  <div className='payment_row'>
                    <span>Delivery fee</span>
                    <span>$ {props.deliveryCharge ?? '00.00'}</span>
                  </div>
                  {props.age && props.age < 25 && (
                    <div className='payment_row'>
                      <span>Young driver fee</span>
                      <span>$ {youngRenterFee?.toFixed(2)}</span>
                    </div>
                  )}
                  <div className='payment_row'>
                    <span>Tax ( {systemConfigurations?.tax} ) </span>
                    <span>$ {taxAmount.toFixed(2)}</span>
                  </div>
                  <div className='payment_row total_payment_row '>
                    <span>Total</span>
                    <span>
                      {/* $ {carCheckoutPrice + Number(props.deliveryCharge)} */}
                      $ {totalBookingAmount?.toFixed(2)}
                      {/* {(discountedPrice ?? carCheckoutPrice) +
                    Number(props.deliveryCharge)} */}
                    </span>
                    {/* <span>$ {carCheckoutPrice - discountPrice}</span> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
      <Modal
        open={infoModal}
        onClose={() => setInfoModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal info_modal'>
          <h3>
            Information
            <div
              onClick={() => {
                setInfoModal(false);
              }}
              className='editModal_close'
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </h3>
          <div className='conf_text'>
            This verification is to ensure that the photo provided of your ID is
            legible and that your ID Number matches the image. If you are having
            trouble verifying your ID information we recommend the following
            steps.
          </div>
          <div className='conf_text'>
            1. Check that the Drivers License Number you've entered matches your
            ID.
          </div>
          <div className='conf_text'>
            2. Check that the photo of your ID is clearly visible.
          </div>
          <div className='conf_text'>
            3. If you are still have problems please email us as{' '}
            <a href='mailto:contact@renotahoerentalcar.com'>
              contact@renotahoerentalcar.com
            </a>{' '}
            or call us at <a href='tel:(530)291-1799'>(530)291-1799</a>
          </div>
        </div>
      </Modal>
      <Modal
        open={insuranceErrorModal}
        onClose={() => setInsuranceErrorModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal info_modal'>
          <div className='conf_detail'>
            <h3>
              Information
              <div
                onClick={() => {
                  setInsuranceErrorModal(false);
                }}
                className='editModal_close'
              >
                <svg
                  width='25'
                  height='25'
                  viewBox='0 0 110 110'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </h3>
            <p>
              It looks like your insurance will expire before your rental with
              us occurs. We'll reach out before your rental to check on your new
              insurance information a week or two before your are booked with
              us.{' '}
            </p>
          </div>
          <div className='confirm_modal_btn confirm_payment_btn'>
            <div style={{ display: 'flex', gap: '20px' }}>
              <button onClick={() => navigate(`/payment/${id}`)}>
                Continue
              </button>
              <button onClick={() => setInsuranceErrorModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={ageModal}
        onClose={() => setAgeModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal info_modal'>
          <div className='conf_detail'>
            <h3>
              {props.age < 21 ? 'Minimum Age Requirement' : 'Young Driver Fee'}
            </h3>
            <p>
              {props.age < 21
                ? 'Sorry, due to insurance and an increased chance of accidents we do not rent to anyone below the age of 21.'
                : `We are really excited for you to be booking with us! However, due to the increased likelihood of accidents for young drivers, a fee of ${systemConfigurations?.young_renter_fee}% will be added to your final booking cost.`}
            </p>
          </div>
          <div className='confirm_modal_btn confirm_payment_btn'>
            <div style={{ display: 'flex', gap: '20px' }}>
              <button onClick={() => setAgeModal(false)}>Cancel</button>
              {props.age >= 21 && (
                <button onClick={() => navigate(`/payment/${id}`)}>
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
