import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import { get_car_by_id } from '../../../API/index';
import { format, intervalToDuration } from 'date-fns';
import { get_car_validate_reference } from '../../../API/index';
import toast from 'react-hot-toast';
import ErrorModal from '../../ErrorModal';
import { getCarName } from '../../../helpers/carName';

export default function CarCard({
  id,
  key,
  img,
  title,
  similarTo,
  type,
  noOfPeople,
  noOfBags,
  dailyRate,
  totalCost,
  cardPath,
  selectPath,
  fromDate,
  fromTime,
  untilDate,
  untilTime,
  model,
  make,
  bookingPeriod,
  unavailable,
  car,
  driveTrain,
  deliveryCharge = null
}) {
  const format_date_time = (date, time) => {
    return date && format(date, 'yyyy-MM-dd') + 'T' + time;
  };
  const navigate = useNavigate();
  const [carDetail, setCarDetail] = useState(null);

  const [carCurrentPrice, setCarCurrentPrice] = useState(dailyRate);
  const [carCheckoutPrice, setCarCheckoutPrice] = useState(null);
  const [meetsMinimumDuration, setMeetsMinimumDuration] = useState(null);
  // const [unavailable, setUnavailable] = useState(false);
  // console.log(unavailable);
  // console.log(unavailable);

  const today = format(new Date(), 'yyyy-MM-dd');
  const getInterval = (fromDate, untilDate) => {
    return intervalToDuration({
      start: fromDate,
      end: untilDate,
    }).days;
  };
  useEffect(() => {
    const start_date = format_date_time(fromDate, fromTime);
    const end_date = format_date_time(untilDate, untilTime);
    get_car_by_id(id, start_date, end_date).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        setCarDetail(res.data);
      } else {
        console.log(res);
      }
    });
  }, []);
  // Setting Car Current Price
  useEffect(() => {
    // const getCarPrice = () => {
    //   const basePrice = carDetail?.daily_price;
    //   // return carDetail?.custom_prices.forEach((customPrice) => {
    //   return carDetail?.custom_prices.every((customPrice) => {
    //     if (today >= customPrice.start_date && today <= customPrice.end_date) {
    //       setCarCurrentPrice(customPrice.price);
    //       return false;
    //     } else {
    //       setCarCurrentPrice(basePrice);
    //       return true;
    //     }
    //   });
    // };
    // getCarPrice();
    if (carDetail) {
      setCarCurrentPrice(carDetail.daily_price.toFixed(2))
    }
    const meetsMinimumDuration = () => {
      const minimumDuration = carDetail?.trip_preference?.min_trip_duration;
      if (bookingPeriod < minimumDuration) {
        setMeetsMinimumDuration(false);
      } else {
        setMeetsMinimumDuration(true);
      }
    };
    meetsMinimumDuration();
  }, [carDetail, bookingPeriod]);

  useEffect(() => {
    // const interval = getInterval(new Date(fromDate || Date.now()), new Date(untilDate || Date.now()));
    const carUpdatedPrice =
      Number(carCurrentPrice &&
      carCurrentPrice * (bookingPeriod === 0 ? 1 : bookingPeriod) +
        (deliveryCharge && (bookingPeriod < 7) ? Number(deliveryCharge) : 0));
    setCarCheckoutPrice(parseInt(carUpdatedPrice));
  }, [carCurrentPrice]);
  // console.log(bookingPeriod);
  const handleMoveInfoPage = (id) => {
    const fromDateTime = format(fromDate || new Date(), 'yyyy-MM-dd') + 'T' + (fromTime || '00:00') + ':00.000Z'; //prettier-ignore

    const untilDateTime = format(untilDate || new Date(), 'yyyy-MM-dd') + 'T' + (untilTime || '00:00') + ':00.000Z'; //prettier-ignore

    // get_car_validate_reference(id, fromDateTime, untilDateTime).then((res) => {
    //   if (res.status === 200) {
    //     navigate(cardPath);
    //   } else {
    //     ErrorModal(res.response.data.message);
    //   }
    // });
    navigate(cardPath);
  };

  const handleNextCarDetailPage = (e) => {
    e.preventDefault();
    // navigate(cardPath);
    // **************************
    handleMoveInfoPage(id);
  };

  return (
    <div
      key={key}
      className='carCard_wrapper'
      onClick={(e) => handleNextCarDetailPage(e)}
    >
      <div className='carCard_hero_img'>
        <img src={img} alt='car_1' />
        {/* <img src={'http://localhost:8000/media/cars/car_ljLt96h.png'} alt='car_1' />
        <img src={'http://localhost:8000/media/cars/IMG_8835.jpg'} alt='car_1' /> */}
        {!meetsMinimumDuration ? (
          <div className='unavailableMark'>
            {`${carDetail?.trip_preference?.min_trip_duration} Day Minimum`}
          </div>
        ) : unavailable ? (
          <div className='unavailableMark'>Unavailable</div>
        ) : (
          ''
        )}
        {/* <img src={`/assets/imgs/cars/${img}.png`} alt='car_1' /> */}
        {/* <span className='mimTripDurationBatch'>
          {carDetail?.trip_preference?.min_trip_duration + ' Day Minimum'}
        </span> */}
      </div>
      <div className='carCard_body'>
        <div className='carCard_title'>{getCarName(car)}</div>
        <div className='car_details'>
          <div className='car_similar_to'>{similarTo}</div>
          <div style={{ display: 'none' }} className='price_details'>
            Features & Price Details
            <svg
              width='8'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                fill='#4FC8E9'
              />
            </svg>
          </div>
        </div>
        <div className='car_tags'>
          <span>
            <img src='/assets/imgs/automatic.png' alt='' />
            {driveTrain}
          </span>
          <span>
            <img src='/assets/imgs/car.png' alt='' />
            {/* <TimeToLeaveIcon></TimeToLeaveIcon> */}
            {noOfPeople}
          </span>
          <span>
            <img src='/assets/imgs/people.png' alt='' />
            {noOfBags} Passengers
          </span>
        </div>

        <div className='car_costs'>
          <div className='daily_rate'>Daily Rate: $ {carCurrentPrice}</div>
          <div className='total_cost'>Total Cost: $ {carCheckoutPrice}.00</div>
        </div>

        <div className='car_select_btn'>
          {!meetsMinimumDuration ? (
            <button
              // onClick={(e) => handleNextCarDetailPage(e)}
              className='unavailable'
              // disabled
            >
              Check Available Dates
            </button>
          ) : unavailable ? (
            <button
              // onClick={(e) => handleNextCarDetailPage(e)}
              className='unavailable'
              // disabled
            >
              Unavailable
            </button>
          ) : (
            <button
              className='select_btn'
              // onClick={() => navigate(selectPath)}
              // onClick={(e) => handleNextCarDetailPage(e)}
              type='submit'
            >
              Select
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
