import Navbar from '../../Components/Navbar';
import { useFormik } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import './index.css';
import toast from 'react-hot-toast';
import ErrorModal from '../../Components/ErrorModal';
import { post_contact } from '../../API/contact';

export default function ContactUs() {
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
      phone: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        post_contact(values).then((res) => {
          if (res.status === 200) {
            toast((t) => (
              <span className="custom-toast">
                Your email has been sent!
                <div onClick={() => toast.dismiss(t.id)} className='close'>
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 110 110'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                      stroke='#FF2552'
                      strokeWidth='11.625'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                      stroke='#FF2552'
                      strokeWidth='11.625'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </span>
            ), {duration: 200000});
          } else {
            toast((t) => (
              <span className="custom-toast">
                Mail delivery failed.
                <div onClick={() => toast.dismiss(t.id)} className='close'>
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 110 110'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                      stroke='#FF2552'
                      strokeWidth='11.625'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                      stroke='#FF2552'
                      strokeWidth='11.625'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </span>
            ), {duration: 200000});
          }
        });
      } catch (e) {
        ErrorModal('Submit failed');
      }
    },
  });

  return (
    <div>
      <Navbar search={true} />
      <div className='contact_us'>
        <div className='login_page left_layout'>
          <div className='login_wrapper'>
            <div className='loginDetails'>
              <div className='loginDetails_heading_wrapper'>
                <div className='paymentDetails_heading'>Contact Us</div>

                {/* 
            <div className='paymentDetailMsgs'>{errorMsg && errorMsg}</div> */}
              </div>
              <form
                action='#'
                onSubmit={formik.handleSubmit}
                className='login_inputs'
              >
                <div className='input_row'>
                  Name
                  <div className='login_input'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                      />
                    </svg>
                    <input
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      required
                      placeholder='Enter Name'
                      name='name'
                      id='name'
                    />
                  </div>
                </div>
                <div className='input_row'>
                  Email
                  <div className='login_input'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M21.333 4H2.66634C2.31272 4 1.97358 4.14048 1.72353 4.39052C1.47348 4.64057 1.33301 4.97971 1.33301 5.33333V18.6667C1.33301 19.0203 1.47348 19.3594 1.72353 19.6095C1.97358 19.8595 2.31272 20 2.66634 20H21.333C21.6866 20 22.0258 19.8595 22.2758 19.6095C22.5259 19.3594 22.6663 19.0203 22.6663 18.6667V5.33333C22.6663 4.97971 22.5259 4.64057 22.2758 4.39052C22.0258 4.14048 21.6866 4 21.333 4ZM20.3063 18.6667H3.77301L8.43967 13.84L7.47967 12.9133L2.66634 17.8933V6.34667L10.953 14.5933C11.2028 14.8417 11.5408 14.9811 11.893 14.9811C12.2453 14.9811 12.5832 14.8417 12.833 14.5933L21.333 6.14V17.8067L16.4263 12.9L15.4863 13.84L20.3063 18.6667ZM3.53967 5.33333H20.253L11.893 13.6467L3.53967 5.33333Z'
                        fill='#BEBEBE'
                      />
                    </svg>

                    <input
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      required
                      type='text'
                      placeholder='Enter Email Address'
                      name='email'
                      id='email'
                    />
                  </div>
                </div>
                <div className='tnc_checkbox'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: '#8D94BE',
                          // padding: '0',
                          '&.Mui-checked': {
                            color: '#4FC8E9',
                          },
                        }}
                      />
                    }
                    label={<>Request a call back</>}
                  />
                </div>
                <div className='input_row'>
                  Phone (Optional)
                  <div className='login_input'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3'
                      />
                    </svg>
                    <input
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      type='text'
                      placeholder='Enter Phone'
                      name='phone'
                      id='phone'
                    />
                  </div>
                </div>
                <div className='input_row'>
                  Message
                  <div className='login_input'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z'
                      />
                    </svg>
                    <textarea
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      required
                      placeholder='Enter Message'
                      name='message'
                      id='message'
                    />
                  </div>
                </div>
                <div className='confirmation_btn'>
                  <Button type='submit'>
                    {formik.isSubmitting ? 'Loading...' : 'Submit'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='right_layout'>
          <div>
            Phone: <span style={{ color: '#4fc8e9' }}> (530) 291-1799</span>
          </div>
          <div>
            Email:{' '}
            <span style={{ color: '#4fc8e9' }}>
              {' '}
              Contact@renotahoerentalcar.com
            </span>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
