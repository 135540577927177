import React, { useContext, useEffect } from 'react';
import Card from '../../Components/CardsWrapper/Card';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';
import TimingsCard from '../../Components/TimingsCard';
import './index.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { format, intervalToDuration, addDays } from 'date-fns';
// import { AuthContext } from '../../contexts/AuthContext';
// import { subfix_domain } from '../../api';

export default function HomePage({ ...props }) {
  const themeContext = useContext(ThemeContext);
  // console.log(themeContext);

  useEffect(() => {
    document.querySelector("body").scrollTo(0,0)
    console.log(intervalToDuration({
      end: new Date('2023-09-03T10:00:00'),
      start: new Date('2023-08-31T13:00:00'),
    }).days)
  }, []);

  // const { authAxios } = useContext(AuthContext);
  // useEffect(() => {
  //   authAxios.post('/api/cars/test/', { subfix_domain})
  // }, []);

  return (
    <div className='homePage'>
      <Navbar />
      <main style={{ flexDirection: 'column' }}>
        <img
          src={themeContext?.main_image ?? 'assets/imgs/hero.png'}
          alt='hero img'
        />
        {/* <img src={themeContext?.main_image} alt='hero img' /> */}
        <div className='img_wrapper'></div>
        <div className='hero_text'>
          {themeContext?.main_text ?? ''}
        </div>
        <TimingsCard
          props={props}
          search={true}
          className={'landingTimingsCard'}
        />
      </main>
      <div className='card_wrapper'>
        <div className='card_container'>
          <Card
            img='car'
            heading='Quality Vehicles'
            body='All of our vehicles are the highest trim levels available and are meticulously maintained, so our renters are always in a vehicle that is both luxurious and reliable. In the winter, our fleet is entirely 4WD and AWD, comes equipped with snow tires to bypass chain control, and we include ski racks to help you get to the slopes.'
          />
          <Card
            img='plane'
            heading='Hassle-free Process'
            body="With our 24-hour reservation system, you can set your pick-up and drop-off for your rental car anytime, day or night. We track flights in real time, so you won't have to worry about missing your ride if your flight is delayed. We collect your info ahead of time, so our pick-up and return process takes just a few minutes."
          />
          <Card
            img='diamond'
            heading='Personalized Service'
            body="We work with our renters to ensure they are in the vehicle that suits them; from seats to luggage space, we want you to have what you need. Plus, with our transparent pricing, you'll know exactly what you're paying - no hidden fees or surprises when you reach the end of our checkout process."
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
