import React, { useEffect, useState } from 'react';
import usePagination from '../../../hooks/usePagination';
import Pagination from '@mui/material/Pagination';
import CarCard from '../../CardsWrapper/CarCard';
import carDummyData from '../../../fixtures/carsDummyData.json';
import CircularProgress from '@mui/material/CircularProgress';
import { getCarUnavailablity } from '../../../helpers/unavailablity';
import Box from '@mui/material/Box';
import './index.css';

import { get_all_cars } from '../../../API/index';

export default function CarCarousel({
  fromDate,
  untilDate,
  fromTime,
  untilTime,
  bookingPeriod,
}) {
  let [page, setPage] = useState(1);
  const PER_PAGE = 3;

  const [allCars, setAllCars] = useState([]);

  useEffect(() => {
    get_all_cars('', true).then((res) => {
      if (res.status === 200 || res.status === 304) {
        setAllCars({
          pagination: {
            count: res?.data?.count,
            total_pages: res?.data?.total_pages,
            page_number: res?.data?.page_number,
          },
          data: res?.data?.results.slice(0, 3).map((car) => ({
            ...car,
            unavailable: getCarUnavailablity(
              car.unavailabilities,
              car.car_bookings,
              car.trip_preference,
              fromDate,
              untilDate,
              fromTime,
              untilTime
            ),
          })),
        });
      } else {
        setAllCars([]);
      }
    });
  }, []);
  // console.log(allCars && allCars.every((e) => e !== undefined));
  // console.log(allCars && allCars.some((e) => e !== undefined));

  const count = Math.ceil(allCars?.length / PER_PAGE);
  const _DATA = usePagination(allCars && allCars, PER_PAGE);
  // console.log(_DATA);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className='quick_look_carousel'>
      <div className='quick_look_carousel_header'>
        <div style={{ margin: '1rem 0' }} className='heading'>
          FOR YOUR QUICK LOOK
        </div>
        {/* <Pagination
          className='quick_look_carousel_pagination'
          shape='circular'
          count={count}
          page={page}
          onChange={handlePageChange}
        /> */}
      </div>
      <div className='quick_look_carousel_wrapper'>
        {allCars ? (
          !allCars?.data?.some((e) => e !== undefined) ? (
            <div>No Featured Vehicles</div>
          ) : (
            allCars?.data?.map(
              (car, index) =>
                car && (
                  <CarCard
                    id={car.id}
                    key={car.id}
                    img={car.car_images[0]?.image}
                    title={car.title}
                    similarTo={''}
                    type={car.type}
                    model={car.model}
                    make={car.make}
                    car={car}
                    noOfPeople={car.number_of_seats}
                    noOfBags={car.number_of_bags}
                    driveTrain={car.drive_train}
                    dailyRate={car.daily_price}
                    totalCost={car.total_price}
                    cardPath={`/car/${car.id}`}
                    selectPath={`/info/${car.id}`}
                    fromDate={fromDate}
                    untilDate={untilDate}
                    bookingPeriod={bookingPeriod}
                    unavailable={car.unavailable}
                  />
                )
            )
          )
        ) : (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
        {/* {_DATA.currentData().map((car, index) => (
          <CarCard
            id={car.id}
            key={car.id}
            img={car.car_images[0]?.image}
            title={car.title}
            similarTo={''}
            type={car.type}
            model={car.model}
            make={car.make}
            car={car}
            noOfPeople={car.number_of_seats}
            noOfBags={car.number_of_bags}
            dailyRate={car.daily_price}
            totalCost={car.total_price}
            cardPath={`/car/${car.id}`}
            selectPath={`/info/${car.id}`}
            fromDate={fromDate}
            untilDate={untilDate}
          />
        ))} */}
      </div>
      {/* <div>
        <Pagination
          className='quick_look_carousel_pagination quick_look_carousel_pagination_mobile'
          shape='circular'
          count={count}
          page={page}
          onChange={handlePageChange}
        />
      </div> */}
    </div>
  );
}
