import React from 'react';
import './index.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ErrorModal = (message) => {
  return confirmAlert({
    overlayClassName: 'errorModal',
    customUI: ({ onClose }) => {
      return (
        <div className='editModal tripDetailModal'>
          <div className='header'>
            <svg
              onClick={() => onClose()}
              width='28'
              height='28'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div style={{ textAlign: 'center' }}>{message}</div>
        </div>
      );
    },
  });
};

export default ErrorModal;
