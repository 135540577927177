import axios from 'axios';
import { API_URL } from '../api';

class AuthService {
  setUserInLocalStorage(data) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  async login(email, password, company) {
    const response = await axios.post(API_URL + 'api/auth/auth-token/', {
      email,
      password,
      subfix_domain: company,
    });
    if (!response.data.token) {
      return response.data;
    }
    this.setUserInLocalStorage(response.data);
    this.getCurrentUser();
    return response.data;
  }

  logout() {
    localStorage.removeItem('user');
  }

  getCurrentUser() {
    const user = localStorage.getItem('user');
    if (!user) {
      return {
        username: 'anomynous',
        token: 'anomynous',
        email: ''
      };
    }

    return JSON.parse(user);
  }
}

export default new AuthService();
