import { api, subfix_domain } from '../api';

const post_contact = async (data) => {
  try {
    const res = await api.post(`/api/contact/`, {
        ...data,
        subfix_domain,
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
    post_contact
};