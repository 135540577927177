import moment from 'moment';

// export const getCarUnavailablity = (unavailabilities, vehicle_status, fromDate, untilDate) => {
//   return unavailabilities.length > 0
//     ? unavailabilities?.some((car) => {
//         // console.log(fromDate);
//         console.log(new Date(car.start_date));
//         if (
//           (fromDate > new Date(car.start_date) &&
//             fromDate < new Date(car.end_date)) ||
//           (untilDate > new Date(car.start_date) &&
//             untilDate < new Date(car.end_date))
//         ) {
//           // setUnavailable(true);
//           return false;
//         } else {
//           // setUnavailable(false);
//           return vehicle_status !== 'prepped' ? false : true;
//           // return true;
//         }
//       })
//     : vehicle_status !== 'prepped'
//     ? false
//     : true;
// };
const getDiffTime = (targetDate, targetTime, other) => {
  return moment(
    moment(targetDate).format('YYYY-MM-DD') + 'T' + targetTime
  ).diff(moment(other));
};

const getDiffPlusTime = (targetDate, targetTime, other, extra_hours) => {
  return moment(
    moment(targetDate).format('YYYY-MM-DD') + 'T' + targetTime + ':00'
  ).diff(moment(other).add(Number(extra_hours), 'hours'));
};

const getDiffSubtractTime = (targetDate, targetTime, other, extra_hours) => {
  return moment(
    moment(targetDate).format('YYYY-MM-DD') + 'T' + targetTime + ':00'
  ).diff(moment(other).subtract(Number(extra_hours), 'hours'));
};

const getDaysBetwDates = (fromDate, untilDate, fromTime, untilTime) => {
  return moment(moment(untilDate).format('YYYY-MM-DD') + 'T' + untilTime).diff(
    moment(moment(fromDate).format('YYYY-MM-DD') + 'T' + fromTime),
    'days'
  );
};

export const getCarUnavailablity = (
  unavailabilities,
  car_bookings,
  trip_preference,
  fromDate,
  untilDate,
  fromTime,
  untilTime
) => {
  // console.log(unavailabilities, car_bookings, trip_preference, fromDate, untilDate, fromTime, untilTime)
  if (
    trip_preference &&
    trip_preference.min_trip_duration &&
    trip_preference.max_trip_duration
  ) {
    const booked_days = getDaysBetwDates(
      fromDate,
      untilDate,
      fromTime,
      untilTime
    );
    if (booked_days < trip_preference.min_trip_duration - 1) {
      return true;
    }
    if (
      trip_preference.max_trip_duration !== 0 &&
      booked_days > trip_preference.max_trip_duration
    ) {
      return true;
    }
  }

  const trip_buffer_time = trip_preference?.trip_buffer_time || 0;
  const unavailability = unavailabilities?.some((car) => {
    if (
      !(
        getDiffTime(fromDate, fromTime, car.end_date) >= 0 ||
        getDiffTime(untilDate, untilTime, car.start_date) <= 0
      )
    ) {
      // unavailablity
      return true;
    }
  });
  // This condiition is already checked on backend side
  const car_booking_used = car_bookings?.filter(booking => booking.status !== "Cancelled").some((booking) => {
    if (
      !(
        getDiffPlusTime(
          fromDate,
          fromTime,
          booking.end_date,
          trip_buffer_time
        ) >= 0 ||
        getDiffSubtractTime(
          untilDate,
          untilTime,
          booking.start_date,
          trip_buffer_time
        ) <= 0
      )
    ) {
      // unavailablity
      return true;
    }
  });

  return unavailability || car_booking_used;
};
