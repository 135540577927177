import React, { createContext, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosInstance } from 'axios';

import authHeader from '../services/AuthHeader';
import AuthService from '../services/AuthService';
import { API_URL, MAIN_HOST } from '../api';

const DefaultProps = {
  login: () => null,
  logout: () => null,
  authAxios: axios,
  user: null,
};

export const AuthContext = createContext(DefaultProps);

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => AuthService.getCurrentUser());

  async function login(email, password, company) {
    const data = await AuthService.login(email, password, company);
    setUser(data);
    return data;
  }

  function logout() {
    AuthService.logout();
    setUser(null);
    window.location.href = MAIN_HOST + '/login';
    // navigate('/login');
  }

  // axios instance for making requests
  const authAxios = axios.create();

  // request interceptor for adding token
  authAxios.interceptors.request.use((config) => {
    // add token to request headers
    config.headers = authHeader();
    config.baseURL = API_URL;
    return config;
  });

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        // logout();
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider value={{ user, login, logout, authAxios }}>
      {children}
    </AuthContext.Provider>
  );
};
