import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/Navbar';
import PaymentDetails from '../../Components/PaymentDetails';
import { get_car_by_id } from '../../API/index';
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';
import { format_date_time } from '../../helpers/formatDatTime';
import { useStripe, useElements, CardNumberElement, CardExpiryElement ,CardCvcElement} from '@stripe/react-stripe-js';

export default function PaymentPage({ ...props }) {
  const stripe = useStripe();
  console.log('🚀OUTPUT --> props:', props);
  const { id } = useParams();
  const navigate = useNavigate();
  // const data = carDummyData.find((e) => e.id === id);
  const [carData, setCarData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    get_car_by_id(id, format_date_time(props.fromDate, props.fromTime), format_date_time(props.untilDate, props.untilTime)).then((res) => {
      if (res.status === 200 || res.status === 304) {
        // console.log(res.data);
        setCarData(res.data);
      } else {
        setCarData([]);
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.dropoffAirportName || props.dropoffAirportName === '') {
      navigate('/');
    }
  }, []);
  //   console.log(id);

  useEffect(() => {
    props.setCardNumber('');
    props.setCardCvv('');
    props.setExpiryDate('');
    props.setExpiryYear('');
    props.setCardHolderName('');
    props.setPostalCode('');
  }, []);

  return (
    <div>
      <NavBar />
      {loading && <div className='mainLoader'>
        <div className='mainLoader_inner'>
          <CircularProgress color='primary' />
        </div>
      </div>}
      <div className='payment_page'>
        <FeatureToast step={5} props={props} />
        {/* PaymentPage */}
        <PaymentDetails variables={props} onLoading={(value) => setLoading(value)}/>
      </div>
      <Footer />
    </div>
  );
}
