import { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams } from 'react-router';
import './index.css';
import {Buffer} from 'buffer';
import { MAIN_HOST } from '../../api';

export default function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { user, login } = useContext(AuthContext);
  const { buffString } = useParams();

  let buff = Buffer.from(buffString, 'base64');
  let text = buff.toString('ascii');
  const { email, password, company } = JSON.parse(text);

  useEffect(() => {
    const login_ = async () => {
      try {
        await login(email, password, company);
      }
      catch(e) {
        // window.location.href = `${MAIN_HOST}/login/error`;
      }
    }

    if(company && email && password) login_();
  }, [company, email, password])

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (<></>);
}