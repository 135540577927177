import React from 'react';
import './index.css';

export default function Card({ img, heading, body }) {
  return (
    <div className='cards'>
      <div className='cards_img'>
        {img === 'car' ? (
          <img src='/assets/imgs/car.png' alt={img} />
        ) : img === 'plane' ? (
          <img src='/assets/imgs/plane.png' alt={img} />
        ) : img === 'diamond' ? (
          <img src='/assets/imgs/diamond.png' alt={img} />
        ) : null}
      </div>
      <div className='cards_heading'>{heading}</div>
      <div className='cards_body'>{body}</div>
    </div>
  );
}
