import { api, subfix_domain } from '../api';

const get_coupon = async (code) => {
  try {
    const res = await api.get(`/api/discounts/coupons/single/`, {
      params: { code, subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const validate_coupon_amount = async (authAxios, price, coupon_code) => {
  try {
    const res = await authAxios.get(`/api/discounts/validate-coupon-amount`, {
      params: { subfix_domain, price, coupon_code },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
    get_coupon,
    validate_coupon_amount
};
