import { subfix_domain } from '../api';

const get_customers = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/customers/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_user = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/customers/${id}`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_user = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/customers/customer-register/`, {
      ...data,
      subfix_domain,
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_admins = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/company-user/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_delivery_charges = async (authAxios, isUsed) => {
  try {
    const res = await authAxios.get(
      `/api/settings/delivery-charges/`,
      {
        params: { subfix_domain },
      }
    );
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_company_user = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/company-user/${id}`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_user,
  get_customers,
  create_user,
  get_admins,
  get_all_delivery_charges,
  get_company_user,
};
