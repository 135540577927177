import { subfix_domain } from '../api';
const get_discount_price = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/trips/get-discounted-price/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const cancel_booking = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/trips/cancelled-bookings/`, {
      ...data,
      subfix_domain,
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_booking = async (authAxios, id, formData) => {
  try {
    formData.append('subfix_domain', subfix_domain);
    const res = await authAxios.patch(
      `/api/trips/${id}/`,
      formData
    );
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_cars = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/cars/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_books = async (authAxios, all_bookings) => {
  try {
    const res = await authAxios.get(`/api/trips/`, {
      params: { all_bookings, subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

// const get_car_booking = async (authAxios, id) => {
//   try {
//     const res = await authAxios.get(`/api/trips/${id}/`, { params: {subfix_domain}});
//     return res;
//   } catch (Error) {
//     // const err = Error.response.data.message;
//     return Error;
//   }
// };

// const create_car_image = async (authAxios, data) => {
//   try {
//     const res = await authAxios.post(`/api/cars/create-car-image/`, data);
//     return res;
//   } catch (Error) {
//     // const err = Error.response.data.message;
//     return Error;
//   }
// };

// const delete_car_image = async (authAxios, id) => {
//   try {
//     const res = await authAxios.delete(`/api/cars/delete-car-image/${id}/`, { params: {subfix_domain}});
//     return res;
//   } catch (Error) {
//     // const err = Error.response.data.message;
//     return Error;
//   }
// };

export {
  get_discount_price,
  update_booking,
  cancel_booking,
  get_all_cars,
  get_car_books
};
