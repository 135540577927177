// src/reusable/image-gallery.component.js
import React, { useRef } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './index.css';

export default function CarThumbnailCarousel({
  className,
  title,
  images,
  unavailable,
}) {
  const carousel = useRef();
  // console.log('width', carousel?.current?.itemsRef[0]?.offsetWidth);
  // console.log('height', carousel?.current?.itemsRef[0]?.offsetWidth / 1.77777);
  const width = (carousel?.current?.itemsRef || [])[0]?.offsetWidth;
  const height = width / 1.77777;
  return (
    <div>
      <Carousel
        className={' ' + className}
        transitionTime='1000'
        showStatus={false}
        showIndicators={false}
        swipeable={true}
        dynamicHeight={425}
        // dynamicHeight={(width / 16) * 9}
        ref={carousel}
        // renderArrowNext={}
      >
        {images?.map((img) => (
          //   <div>
          <>
            <img src={img?.image} alt={title}/>
            {unavailable && <div className='unavailableMark'>Unavailable</div>}
          </>

          //   </div>
        ))}
      </Carousel>
    </div>
  );
}
