import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/Navbar';
import UserInfoForm from '../../Components/UserInfoForm';
// import carDummyData from '../../fixtures/carsDummyData.json';
import './index.css';
import { get_car_by_id } from '../../API/index';
import { format_date_time } from '../../helpers/formatDatTime';

export default function InfoPage({ ...props }) {
  const { id } = useParams();
  const navigate = useNavigate();
  // const data = carDummyData.find((e) => e.id === id);
  const [carData, setCarData] = useState(null);

  useEffect(() => {
    get_car_by_id(id, format_date_time(props.fromDate, props.fromTime), format_date_time(props.untilDate, props.untilTime)).then((res) => {
      if (res.status === 200 || res.status === 304) {
        // console.log(res.data);
        setCarData(res.data);
      } else {
        setCarData([]);
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.dropoffAirportName || props.dropoffAirportName === '') {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    console.log('sss');
    clearState();
  }, []);

  const clearState = () => {
    // props.setPostalCode('');
    props.setDriverLicenceImg(null);
    props.setInsuranceImg(null);
    props.setFirstName('');
    props.setLastName('');
    props.setEmail('');
    props.setMobileNo('');
    props.setFlightNo('');
    props.setDriverLicenseNo('');
    props.setAge('');
    props.setAirlineName('');
    props.setInsurancePolicyNo('');
    props.setInsuranceExpDate('');
    props.setInsuranceCompany('');
    // props.setAirportName(null);
    // props.setDeliveryCharge('');
  };

  // console.log(carData);
  return (
    <div>
      <NavBar />
      <div className='info_page'>
        <div className='info_background'>
          <img src='/assets/imgs/bg_info.png' alt='bg_car' />
          <div className='bg_wrapper'></div>
        </div>
        <FeatureToast step={4} props={props} />
        {/* PaymentPage */}
        <UserInfoForm props={props} carData={carData} />
      </div>
      <Footer />
    </div>
  );
}
