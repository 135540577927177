import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Modal from '@mui/material/Modal';
import './index.css';
import { useParams } from 'react-router-dom';
import { get_car_by_id } from '../../../API/index';
import { format_date_time } from '../../../helpers/formatDatTime';

import TimingsCard from '../../../Components/TimingsCard';

export default function FeatureToast({ step, props }) {
  console.log('🚀OUTPUT --> step:', step);
  const [updateTimingModal, setUpdateTimingModal] = useState(false);
  const { id: car_id } = useParams();
  const [carDetail, setCarData] = useState();

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (car_id) {
      get_car_by_id(
        car_id,
        format_date_time(props.fromDate, props.fromTime),
        format_date_time(props.untilDate, props.untilTime)
      ).then((res) => {
        if (res.status === 200) {
          setCarData(res.data);
        } else {
          setCarData({});
        }
      });
    }
  }, [car_id]);

  return (
    <div className='featureToast_wrapper'>
      <div className='featureToast'>
        <div
          // to='/'
          onClick={() => setUpdateTimingModal(true)}
          // onClick={() => UpdateTimingsModal()}
          style={{ borderColor: step > 1 ? null : 'white' }}
          className='step step_1'
        >
          {step > 1 ? (
            <svg width='14' viewBox='0 0 20 16' fill='none'>
              <path
                d='M17.2881 0.947384C17.4075 0.825525 17.5501 0.728717 17.7074 0.662629C17.8647 0.596541 18.0336 0.5625 18.2042 0.5625C18.3748 0.5625 18.5438 0.596541 18.7011 0.662629C18.8584 0.728717 19.0009 0.825525 19.1203 0.947384C19.6208 1.45313 19.6278 2.27038 19.1378 2.78488L8.79009 15.0174C8.6726 15.1464 8.53003 15.2501 8.3711 15.3221C8.21217 15.3941 8.04021 15.433 7.86575 15.4362C7.69129 15.4395 7.518 15.4072 7.35647 15.3412C7.19495 15.2752 7.04858 15.1769 6.92634 15.0524L0.629837 8.67188C0.387002 8.42423 0.250977 8.09123 0.250977 7.74438C0.250977 7.39754 0.387002 7.06453 0.629837 6.81688C0.749268 6.69502 0.89181 6.59822 1.04912 6.53213C1.20642 6.46604 1.37534 6.432 1.54596 6.432C1.71659 6.432 1.8855 6.46604 2.04281 6.53213C2.20012 6.59822 2.34266 6.69502 2.46209 6.81688L7.80309 12.2296L17.2531 0.985885C17.264 0.97236 17.2757 0.959503 17.2881 0.947384Z'
                fill='#4FC8E9'
              />
            </svg>
          ) : (
            '01'
          )}
          <div className='step_text header_rental_details'>
            <div
              className='heading'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Rental Details
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '3px',
                  lineHeight: '20px',
                  marginTop: '-5px',
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                  />
                </svg>
              </div>
            </div>
            <div className='body'>
              {props.fromDate
                ? format(props.fromDate, 'EEE, LLL dd')
                : 'Fri, Apr 15'}
              , {props.fromTime ? tConvert(props.fromTime) : '6:00 AM'}
              <br />
              {props.untilDate
                ? format(props.untilDate, 'EEE, LLL dd')
                : 'Mon, Apr 18'}
              , {props.untilTime ? tConvert(props.untilTime) : '11:30 PM'}
            </div>
          </div>
        </div>
        <div className='step_connect'>
          <svg style={{ left: '50%' }} width='6' viewBox='0 0 8 14' fill='none'>
            <path
              d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
              fill='#4FC8E9'
            />
          </svg>
        </div>
        <div
          // to='/'
          onClick={() => setUpdateTimingModal(true)}
          style={{ borderColor: step > 2 ? null : 'white' }}
          className='step step_2'
        >
          {step > 2 ? (
            <svg width='14' viewBox='0 0 20 16' fill='none'>
              <path
                d='M17.2881 0.947384C17.4075 0.825525 17.5501 0.728717 17.7074 0.662629C17.8647 0.596541 18.0336 0.5625 18.2042 0.5625C18.3748 0.5625 18.5438 0.596541 18.7011 0.662629C18.8584 0.728717 19.0009 0.825525 19.1203 0.947384C19.6208 1.45313 19.6278 2.27038 19.1378 2.78488L8.79009 15.0174C8.6726 15.1464 8.53003 15.2501 8.3711 15.3221C8.21217 15.3941 8.04021 15.433 7.86575 15.4362C7.69129 15.4395 7.518 15.4072 7.35647 15.3412C7.19495 15.2752 7.04858 15.1769 6.92634 15.0524L0.629837 8.67188C0.387002 8.42423 0.250977 8.09123 0.250977 7.74438C0.250977 7.39754 0.387002 7.06453 0.629837 6.81688C0.749268 6.69502 0.89181 6.59822 1.04912 6.53213C1.20642 6.46604 1.37534 6.432 1.54596 6.432C1.71659 6.432 1.8855 6.46604 2.04281 6.53213C2.20012 6.59822 2.34266 6.69502 2.46209 6.81688L7.80309 12.2296L17.2531 0.985885C17.264 0.97236 17.2757 0.959503 17.2881 0.947384Z'
                fill='#4FC8E9'
              />
            </svg>
          ) : (
            '02'
          )}
          <div className='step_text'>
            <div className='heading'>Pickup & Return </div>
            <div className='body'>{props.airportName ?? ''}</div>
            {props.isDifferentDropoff &&
              props.airportName !== props.dropoffAirportName && (
                <div className='body'>{props.dropoffAirportName}</div>
              )}
          </div>
        </div>
        <div className='step_connect'>
          <svg style={{ left: '60%' }} width='6' viewBox='0 0 8 14' fill='none'>
            <path
              d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
              fill='#4FC8E9'
            />
          </svg>
        </div>
        <Link
          to='/search'
          style={{ borderColor: step > 3 ? null : 'white' }}
          className='step step_3'
        >
          {step > 3 ? (
            <svg width='14' viewBox='0 0 20 16' fill='none'>
              <path
                d='M17.2881 0.947384C17.4075 0.825525 17.5501 0.728717 17.7074 0.662629C17.8647 0.596541 18.0336 0.5625 18.2042 0.5625C18.3748 0.5625 18.5438 0.596541 18.7011 0.662629C18.8584 0.728717 19.0009 0.825525 19.1203 0.947384C19.6208 1.45313 19.6278 2.27038 19.1378 2.78488L8.79009 15.0174C8.6726 15.1464 8.53003 15.2501 8.3711 15.3221C8.21217 15.3941 8.04021 15.433 7.86575 15.4362C7.69129 15.4395 7.518 15.4072 7.35647 15.3412C7.19495 15.2752 7.04858 15.1769 6.92634 15.0524L0.629837 8.67188C0.387002 8.42423 0.250977 8.09123 0.250977 7.74438C0.250977 7.39754 0.387002 7.06453 0.629837 6.81688C0.749268 6.69502 0.89181 6.59822 1.04912 6.53213C1.20642 6.46604 1.37534 6.432 1.54596 6.432C1.71659 6.432 1.8855 6.46604 2.04281 6.53213C2.20012 6.59822 2.34266 6.69502 2.46209 6.81688L7.80309 12.2296L17.2531 0.985885C17.264 0.97236 17.2757 0.959503 17.2881 0.947384Z'
                fill='#4FC8E9'
              />
            </svg>
          ) : (
            '03'
          )}
          <div className='step_text'>
            <div className='heading'>Vehicle </div>
            <div className='body'>
              {carDetail
                ? carDetail.make + ' ' + carDetail.model + ' '
                : 'Select'}
            </div>
          </div>
        </Link>
        <div className='step_connect'>
          <svg style={{ left: '60%' }} width='6' viewBox='0 0 8 14' fill='none'>
            <path
              d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
              fill='#4FC8E9'
            />
          </svg>
        </div>
        <Link
          to={'/info/' + window.localStorage.getItem('car_id')}
          style={{
            borderColor: step > 4 ? null : 'white',
            pointerEvents: step < 4 && 'none',
          }}
          className='step step_4'
        >
          {step > 4 ? (
            <svg width='14' viewBox='0 0 20 16' fill='none'>
              <path
                d='M17.2881 0.947384C17.4075 0.825525 17.5501 0.728717 17.7074 0.662629C17.8647 0.596541 18.0336 0.5625 18.2042 0.5625C18.3748 0.5625 18.5438 0.596541 18.7011 0.662629C18.8584 0.728717 19.0009 0.825525 19.1203 0.947384C19.6208 1.45313 19.6278 2.27038 19.1378 2.78488L8.79009 15.0174C8.6726 15.1464 8.53003 15.2501 8.3711 15.3221C8.21217 15.3941 8.04021 15.433 7.86575 15.4362C7.69129 15.4395 7.518 15.4072 7.35647 15.3412C7.19495 15.2752 7.04858 15.1769 6.92634 15.0524L0.629837 8.67188C0.387002 8.42423 0.250977 8.09123 0.250977 7.74438C0.250977 7.39754 0.387002 7.06453 0.629837 6.81688C0.749268 6.69502 0.89181 6.59822 1.04912 6.53213C1.20642 6.46604 1.37534 6.432 1.54596 6.432C1.71659 6.432 1.8855 6.46604 2.04281 6.53213C2.20012 6.59822 2.34266 6.69502 2.46209 6.81688L7.80309 12.2296L17.2531 0.985885C17.264 0.97236 17.2757 0.959503 17.2881 0.947384Z'
                fill='#4FC8E9'
              />
            </svg>
          ) : (
            '04'
          )}
          <div className='step_text'>
            <div className='heading'>Info</div>
            <div className='body'></div>
          </div>
        </Link>
        <div className='step_connect'>
          <svg style={{ left: '70%' }} width='6' viewBox='0 0 8 14' fill='none'>
            <path
              d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
              fill='#4FC8E9'
            />
          </svg>
        </div>
        <Link
          to=''
          style={{
            borderColor: step > 5 ? null : 'white',
            pointerEvents: step < 5 && 'none',
          }}
          className='step step_5'
        >
          {step > 5 ? (
            <svg width='14' viewBox='0 0 20 16' fill='none'>
              <path
                d='M17.2881 0.947384C17.4075 0.825525 17.5501 0.728717 17.7074 0.662629C17.8647 0.596541 18.0336 0.5625 18.2042 0.5625C18.3748 0.5625 18.5438 0.596541 18.7011 0.662629C18.8584 0.728717 19.0009 0.825525 19.1203 0.947384C19.6208 1.45313 19.6278 2.27038 19.1378 2.78488L8.79009 15.0174C8.6726 15.1464 8.53003 15.2501 8.3711 15.3221C8.21217 15.3941 8.04021 15.433 7.86575 15.4362C7.69129 15.4395 7.518 15.4072 7.35647 15.3412C7.19495 15.2752 7.04858 15.1769 6.92634 15.0524L0.629837 8.67188C0.387002 8.42423 0.250977 8.09123 0.250977 7.74438C0.250977 7.39754 0.387002 7.06453 0.629837 6.81688C0.749268 6.69502 0.89181 6.59822 1.04912 6.53213C1.20642 6.46604 1.37534 6.432 1.54596 6.432C1.71659 6.432 1.8855 6.46604 2.04281 6.53213C2.20012 6.59822 2.34266 6.69502 2.46209 6.81688L7.80309 12.2296L17.2531 0.985885C17.264 0.97236 17.2757 0.959503 17.2881 0.947384Z'
                fill='#4FC8E9'
              />
            </svg>
          ) : (
            '05'
          )}
          <div className='step_text' style={{ left: '-40px' }}>
            <div className='heading'>Payment</div>
            <div className='body'></div>
          </div>
        </Link>
      </div>
      <Modal
        open={updateTimingModal}
        onClose={() => setUpdateTimingModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal timelineTimingsModal'>
          <TimingsCard props={props} className={'timelineTimingsCard'} />
        </div>
      </Modal>
    </div>
  );
}
