import { AuthContext } from '../../contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_all_delivery_charges } from '../../API/user';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, sub, add } from 'date-fns';
import './index.css';
import ErrorModal from '../ErrorModal';
import { useParams } from 'react-router-dom';
import { get_car_by_id } from '../../API/index';
import { getCarUnavailablity } from '../../helpers/unavailablity';
import toast from 'react-hot-toast';
import { get_car_validate_reference } from '../../API/index';
import { format_date_time } from '../../helpers/formatDatTime';

export default function TimingsCard({ ...prop }) {
  const props = prop.props;
  let navigate = useNavigate();
  const { authAxios } = useContext(AuthContext);
  const [airports, setAirports] = useState([]);
  const [pickupDeliveryCharge, setPickupDeliveryCharge] = useState('');
  const [dropoffDeliveryCharge, setDropoffDeliveryCharge] = useState('');
  const { id: car_id } = useParams();
  const [carDetail, setCarData] = useState();
  const [errorMsg, setErrorMsg] = useState('');

  const format_date = (date) => {
    return date && format(date, 'yyyy-MM-dd');
  };

  const getIntervalDays = (fromDate, fromTime, untilDate, untilTime) => {
    return Math.ceil(
      (new Date(format_date_time(untilDate, untilTime)).getTime() -
        new Date(format_date_time(fromDate, fromTime)).getTime()) /
        (1000 * 60 * 60 * 24)
    );
  };

  const handleFromTimeDisable = (time) => {
    const currentTime = format(new Date(), 'HH:mm');
    const today = format(new Date(), 'yyyy-MM-dd');

    if (format_date(props.fromDate) === today && time < currentTime) {
      return true;
    }
    return getCarUnavailablity(
      carDetail?.unavailabilities,
      carDetail?.car_bookings,
      carDetail?.trip_preference,
      props.fromDate,
      props.untilDate,
      time,
      props.untilTime
    );
  };
  console.log(handleFromTimeDisable(props?.fromTime));

  useEffect(() => {
    const getAllDeliveryCharges = async () => {
      let res = await get_all_delivery_charges(authAxios);
      if (res.status === 200) {
        setAirports(res.data);
      } else {
        ErrorModal(Object.values(res.response.data)[0]);
      }
    };
    getAllDeliveryCharges();
  }, []);

  useEffect(() => {
    if (props.fromDate > props.untilDate) {
      const newDate = add(props.fromDate, { days: 3 });
      props.setUntilDate(newDate);
    }
  }, [props.fromDate]);

  useEffect(() => {
    if (car_id) {
      get_car_by_id(
        car_id,
        format_date_time(props.fromDate, props.fromTime),
        format_date_time(props.untilDate, props.untilTime)
      ).then((res) => {
        if (res.status === 200) {
          setCarData(res.data);
        } else {
          setCarData({});
        }
      });
    }
  }, [car_id]);

  useEffect(() => {
    const fromDate =
      format(props.fromDate || new Date(), 'yyyy-MM-dd') +
      'T' +
      (props.fromTime || '00:00') +
      ':00.000Z';

    const untilDate =
      format(props.untilDate || new Date(), 'yyyy-MM-dd') +
      'T' +
      (props.untilTime || '00:00') +
      ':00.000Z';

    if (car_id) {
      get_car_validate_reference(car_id, fromDate, untilDate).then((res) => {
        if (res.status === 200) {
          setErrorMsg('');
        } else {
          setErrorMsg(res.response.data.message);
        }
      });
    }
  }, [
    props.fromDate,
    props.fromTime,
    props.untilDate,
    props.untilTime,
    car_id,
  ]);

  useEffect(() => {
    let deliveryCharges = 0;
    // If dropoff is same as pickup, set dropoff location as pickup location
    if (!props.isDifferentDropoff && props.airportName) {
      props.setDropoffAirportName(props.airportName);
    }
    if (props.airportName) {
      const pickupAirport = airports?.find(
        ({ airport }) => airport === props.airportName
      );
      const pickupCharge = pickupAirport?.delivery_charge;
      deliveryCharges = deliveryCharges + parseFloat(pickupCharge);
    }
    if (props.dropoffAirportName) {
      const dropoffAirport = airports?.find(
        ({ airport }) => airport === props.dropoffAirportName
      );
      const dropoffCharge = dropoffAirport?.delivery_charge;

      deliveryCharges = deliveryCharges + parseFloat(dropoffCharge);
    }

    const bookingPeriod = getIntervalDays(
      props.fromDate,
      props.fromTime,
      props.untilDate,
      props.untilTime
    );

    let extraDays = 0;
    if (
      Number(props.untilTime.slice(0, 2)) < Number(props.fromTime.slice(0, 2))
    ) {
      extraDays = 1;
    }

    props.setDeliveryCharge(
      bookingPeriod < 7 ? deliveryCharges.toFixed(2).toString() : 0
    );
  });

  return (
    <div className={'timingsCard_wrapper ' + prop.className}>
      <form
        className='timingsCard'
        onSubmit={(e) => {
          e.preventDefault();
          navigate('search');
        }}
      >
        <div className='fieldsWrapper'>
          <div className='destination_select'>
            <div>Pickup</div>
            <FormGroup>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Enter Airport Name</InputLabel>
                <Select
                  required
                  className='airport_name'
                  labelId='airport_name'
                  id='demo-simple-select'
                  value={props.airportName}
                  label='Enter Airport Name'
                  onChange={(e) => props.setAirportName(e.target.value)}
                >
                  {airports ? (
                    airports
                      .sort((a, b) => a.priority - b.priority)
                      .map((airport) => (
                        <MenuItem
                          key={airport.id}
                          className='timings_menu_item'
                          value={airport.airport}
                          onClick={() => {
                            setPickupDeliveryCharge(airport.delivery_charge);
                          }}
                        >
                          <svg
                            style={{ marginRight: '10px' }}
                            width='14'
                            viewBox='0 0 14 17'
                            fill='none'
                          >
                            <path
                              d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                              fill='white'
                            />
                          </svg>
                          {airport.airport}
                        </MenuItem>
                      ))
                  ) : (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Select>
              </FormControl>
              {props.isDifferentDropoff && (
                <>
                  <div className='dropoff-airport-heading'>Return</div>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                    <InputLabel id='airport_name'>
                      Enter Airport Name
                    </InputLabel>
                    <Select
                      required
                      className='airport_name'
                      labelId='dropoff_airport_name'
                      id='dropoff-airport-select'
                      value={props.dropoffAirportName}
                      label='Enter Airport Name'
                      onChange={(e) =>
                        props.setDropoffAirportName(e.target.value)
                      }
                    >
                      {airports ? (
                        airports.map((airport) => (
                          <MenuItem
                            key={airport.id}
                            className='timings_menu_item'
                            value={airport.airport}
                            onClick={() => {
                              setDropoffDeliveryCharge(airport.delivery_charge);
                            }}
                          >
                            <svg
                              style={{ marginRight: '10px' }}
                              width='14'
                              viewBox='0 0 14 17'
                              fill='none'
                            >
                              <path
                                d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                                fill='white'
                              />
                            </svg>
                            {airport.airport}
                          </MenuItem>
                        ))
                      ) : (
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress />
                        </Box>
                      )}
                    </Select>
                  </FormControl>
                </>
              )}
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <FormControlLabel
                  style={{ marginLeft: '0px' }}
                  control={
                    <Checkbox
                      checked={props.isDifferentDropoff}
                      onChange={() =>
                        props.isDifferentDropoff
                          ? props.setIsDifferentDropoff(false)
                          : props.setIsDifferentDropoff(true)
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                          marginRight: '5px',
                        },
                      }}
                    />
                  }
                  label='Return to a different location'
                  sx={{ '& .MuiTypography-root': { fontSize: 12 } }}
                ></FormControlLabel>
              </FormControl>
            </FormGroup>
            <span className='deliveryCharge'>
              Delivery Charge:- ${props.deliveryCharge || '0.00'}
            </span>
          </div>
          <div>
            <div>From</div>
            <div className='date_time'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // shouldDisableDate={(23, 24, 25) => true}
                  disablePast
                  label='Date'
                  // readOnly
                  value={props.fromDate}
                  onChange={(newValue) => {
                    // props.setFromDate(newValue);
                    if (
                      JSON.stringify(newValue) &&
                      JSON.stringify(newValue) !== 'null'
                    ) {
                      if (
                        props.untilDate &&
                        newValue > new Date(props.fromDate)
                      ) {
                        const newUntilDate = add(newValue, { days: 3 });
                        // console.log('🚀OUTPUT --> newFromDate:', newFromDate);
                        props.setFromDate(newValue);
                        props.setUntilDate(newUntilDate);

                        // toast.error('From date should be less then Until Date');
                      } else {
                        props.setFromDate(newValue);
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className='time_date_picker'
                      size='small'
                      onKeyDown={(e) => e.preventDefault()}
                      required
                      sx={{
                        input: {
                          color: getCarUnavailablity(
                            carDetail?.unavailabilities,
                            carDetail?.car_bookings,
                            carDetail?.trip_preference,
                            props.fromDate,
                            props.untilDate,
                            '23:59',
                            props.untilTime
                          )
                            ? 'red'
                            : 'secondary',
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                  <InputLabel id='from_time'>From time</InputLabel>
                  <Select
                    className='airport_name'
                    required
                    labelId='from_time'
                    id='from-time-select'
                    value={props.fromTime}
                    label='From time'
                    style={{
                      color: handleFromTimeDisable(props.fromTime) ? 'red' : '',
                    }}
                    onChange={(e) => props.setFromTime(e.target.value)}
                  >
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'00:30'}
                      disabled={handleFromTimeDisable('00:30')}
                    >
                      {' '}
                      12:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:00'}
                      disabled={handleFromTimeDisable('01:00')}
                    >
                      {' '}
                      01:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:30'}
                      disabled={handleFromTimeDisable('01:30')}
                    >
                      {' '}
                      01:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:00'}
                      disabled={handleFromTimeDisable('02:00')}
                    >
                      {' '}
                      02:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:30'}
                      disabled={handleFromTimeDisable('02:30')}
                    >
                      {' '}
                      02:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:00'}
                      disabled={handleFromTimeDisable('03:00')}
                    >
                      {' '}
                      03:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:30'}
                      disabled={handleFromTimeDisable('03:30')}
                    >
                      {' '}
                      03:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:00'}
                      disabled={handleFromTimeDisable('04:00')}
                    >
                      {' '}
                      04:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:30'}
                      disabled={handleFromTimeDisable('04:30')}
                    >
                      {' '}
                      04:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:00'}
                      disabled={handleFromTimeDisable('05:00')}
                    >
                      {' '}
                      05:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:30'}
                      disabled={handleFromTimeDisable('05:30')}
                    >
                      {' '}
                      05:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:00'}
                      disabled={handleFromTimeDisable('06:00')}
                    >
                      {' '}
                      06:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:30'}
                      disabled={handleFromTimeDisable('06:30')}
                    >
                      {' '}
                      06:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:00'}
                      disabled={handleFromTimeDisable('07:00')}
                    >
                      {' '}
                      07:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:30'}
                      disabled={handleFromTimeDisable('07:30')}
                    >
                      {' '}
                      07:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:00'}
                      disabled={handleFromTimeDisable('08:00')}
                    >
                      {' '}
                      08:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:30'}
                      disabled={handleFromTimeDisable('08:30')}
                    >
                      {' '}
                      08:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:00'}
                      disabled={handleFromTimeDisable('09:00')}
                    >
                      {' '}
                      09:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:30'}
                      disabled={handleFromTimeDisable('09:30')}
                    >
                      {' '}
                      09:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:00'}
                      disabled={handleFromTimeDisable('10:00')}
                    >
                      {' '}
                      10:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:30'}
                      disabled={handleFromTimeDisable('10:30')}
                    >
                      {' '}
                      10:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:00'}
                      disabled={handleFromTimeDisable('11:00')}
                    >
                      {' '}
                      11:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:30'}
                      disabled={handleFromTimeDisable('11:30')}
                    >
                      {' '}
                      11:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:00'}
                      disabled={handleFromTimeDisable('12:00')}
                    >
                      {' '}
                      12:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:30'}
                      disabled={handleFromTimeDisable('12:30')}
                    >
                      {' '}
                      12:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:00'}
                      disabled={handleFromTimeDisable('13:00')}
                    >
                      {' '}
                      01:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:30'}
                      disabled={handleFromTimeDisable('13:30')}
                    >
                      {' '}
                      01:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:00'}
                      disabled={handleFromTimeDisable('14:00')}
                    >
                      {' '}
                      02:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:30'}
                      disabled={handleFromTimeDisable('14:30')}
                    >
                      {' '}
                      02:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:00'}
                      disabled={handleFromTimeDisable('15:00')}
                    >
                      {' '}
                      03:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:30'}
                      disabled={handleFromTimeDisable('15:30')}
                    >
                      {' '}
                      03:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:00'}
                      disabled={handleFromTimeDisable('16:00')}
                    >
                      {' '}
                      04:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:30'}
                      disabled={handleFromTimeDisable('16:30')}
                    >
                      {' '}
                      04:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:00'}
                      disabled={handleFromTimeDisable('17:00')}
                    >
                      {' '}
                      05:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:30'}
                      disabled={handleFromTimeDisable('17:30')}
                    >
                      {' '}
                      05:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:00'}
                      disabled={handleFromTimeDisable('18:00')}
                    >
                      {' '}
                      06:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:30'}
                      disabled={handleFromTimeDisable('18:30')}
                    >
                      {' '}
                      06:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:00'}
                      disabled={handleFromTimeDisable('19:00')}
                    >
                      {' '}
                      07:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:30'}
                      disabled={handleFromTimeDisable('19:30')}
                    >
                      {' '}
                      07:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:00'}
                      disabled={handleFromTimeDisable('20:00')}
                    >
                      {' '}
                      08:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:30'}
                      disabled={handleFromTimeDisable('20:30')}
                    >
                      {' '}
                      08:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:00'}
                      disabled={handleFromTimeDisable('21:00')}
                    >
                      {' '}
                      09:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:30'}
                      disabled={handleFromTimeDisable('21:30')}
                    >
                      {' '}
                      09:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:00'}
                      disabled={handleFromTimeDisable('22:00')}
                    >
                      {' '}
                      10:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:30'}
                      disabled={handleFromTimeDisable('22:30')}
                    >
                      {' '}
                      10:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:00'}
                      disabled={handleFromTimeDisable('23:00')}
                    >
                      {' '}
                      11:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:30'}
                      disabled={handleFromTimeDisable('23:30')}
                    >
                      {' '}
                      11:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'00:00'}
                      disabled={handleFromTimeDisable('00:00')}
                    >
                      {' '}
                      12:00 am{' '}
                    </MenuItem>
                  </Select>
                </FormControl>
              </LocalizationProvider>
            </div>
            {prop.error && <div className='timings_error'>{errorMsg}</div>}
          </div>
          <div>
            <div>Until</div>
            <div className='date_time'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  label='Date'
                  value={props.untilDate}
                  onChange={(newValue) => {
                    // console.log(newValue);
                    // props.setUntilDate(newValue);
                    if (
                      JSON.stringify(newValue) &&
                      JSON.stringify(newValue) !== 'null'
                    ) {
                      if (
                        props.fromDate &&
                        newValue < new Date(props.fromDate)
                      ) {
                        toast.error(
                          'Until date should be greater then From Date'
                        );
                      } else {
                        props.setUntilDate(newValue);
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      size='small'
                      onKeyDown={(e) => e.preventDefault()}
                      className='time_date_picker'
                      {...params}
                    />
                  )}
                  shouldDisableDate={(dateParam) => {
                    //your_condition here
                    //return true to disabled and false to enable
                    // console.log(props.fromDate);
                    const fromFormatted = sub(props.fromDate, { days: 0 })
                      ?.toISOString()
                      .split('T')[0];
                    const currentDate = dateParam.toISOString().split('T')[0];
                    // console.log(fromFormatted);

                    return fromFormatted > currentDate ? true : false;
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                  <InputLabel id='until_time'>Until time</InputLabel>
                  <Select
                    className='airport_name'
                    labelId='until_time'
                    id='until-time-select'
                    required
                    value={props.untilTime}
                    label='Until time'
                    onChange={(e) => props.setUntilTime(e.target.value)}
                  >
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'00:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '00:30'
                      }
                    >
                      {' '}
                      12:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '01:00'
                      }
                    >
                      {' '}
                      01:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '01:30'
                      }
                    >
                      {' '}
                      01:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '02:00'
                      }
                    >
                      {' '}
                      02:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '02:30'
                      }
                    >
                      {' '}
                      02:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '03:00'
                      }
                    >
                      {' '}
                      03:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '03:30'
                      }
                    >
                      {' '}
                      03:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '04:00'
                      }
                    >
                      {' '}
                      04:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '04:30'
                      }
                    >
                      {' '}
                      04:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '05:00'
                      }
                    >
                      {' '}
                      05:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '05:30'
                      }
                    >
                      {' '}
                      05:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '06:00'
                      }
                    >
                      {' '}
                      06:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '06:30'
                      }
                    >
                      {' '}
                      06:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '07:00'
                      }
                    >
                      {' '}
                      07:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '07:30'
                      }
                    >
                      {' '}
                      07:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '08:00'
                      }
                    >
                      {' '}
                      08:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '08:30'
                      }
                    >
                      {' '}
                      08:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '09:00'
                      }
                    >
                      {' '}
                      09:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '09:30'
                      }
                    >
                      {' '}
                      09:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '10:00'
                      }
                    >
                      {' '}
                      10:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '10:30'
                      }
                    >
                      {' '}
                      10:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '11:00'
                      }
                    >
                      {' '}
                      11:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '11:30'
                      }
                    >
                      {' '}
                      11:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '12:00'
                      }
                    >
                      {' '}
                      12:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '12:30'
                      }
                    >
                      {' '}
                      12:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '13:00'
                      }
                    >
                      {' '}
                      01:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '13:30'
                      }
                    >
                      {' '}
                      01:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '14:00'
                      }
                    >
                      {' '}
                      02:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '14:30'
                      }
                    >
                      {' '}
                      02:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '15:00'
                      }
                    >
                      {' '}
                      03:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '15:30'
                      }
                    >
                      {' '}
                      03:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '16:00'
                      }
                    >
                      {' '}
                      04:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '16:30'
                      }
                    >
                      {' '}
                      04:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '17:00'
                      }
                    >
                      {' '}
                      05:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '17:30'
                      }
                    >
                      {' '}
                      05:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '18:00'
                      }
                    >
                      {' '}
                      06:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '18:30'
                      }
                    >
                      {' '}
                      06:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '19:00'
                      }
                    >
                      {' '}
                      07:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '19:30'
                      }
                    >
                      {' '}
                      07:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '20:00'
                      }
                    >
                      {' '}
                      08:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '20:30'
                      }
                    >
                      {' '}
                      08:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '21:00'
                      }
                    >
                      {' '}
                      09:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '21:30'
                      }
                    >
                      {' '}
                      09:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '22:00'
                      }
                    >
                      {' '}
                      10:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '22:30'
                      }
                    >
                      {' '}
                      10:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '23:00'
                      }
                    >
                      {' '}
                      11:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:30'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '23:30'
                      }
                    >
                      {' '}
                      11:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'00:00'}
                      disabled={
                        format_date(props?.fromDate) ===
                          format_date(props?.untilDate) &&
                        props?.fromTime >= '00:00'
                      }
                    >
                      {' '}
                      12:00 am{' '}
                    </MenuItem>
                  </Select>
                </FormControl>
              </LocalizationProvider>
            </div>
          </div>
        </div>
        {prop.search && (
          <div>
            <button
              className={'timingsCard_submit btn btn-secondary'}
              disabled={handleFromTimeDisable(props?.fromTime)}
              // onClick={() => navigate('search')}
            >
              Search
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
