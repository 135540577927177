import React, { useState } from 'react';
import './index.css';
export default function FeaturesCard({ features }) {
  const [showFeature, setShowFeature] = useState(false);
  // features.map((feature) => console.log(feature));
  return (
    <div className='featuresCard'>
      <div className='features_heading'>
        <span>Features</span>
        <span
          onClick={() => setShowFeature((showFeature) => !showFeature)}
          className='featureExpandBtn'
        >
          {!showFeature ? 'Show All' : 'Hide'}
        </span>
      </div>
      <div className={`features_main ${showFeature ? 'showFeatures' : ''}`}>
        {features?.map((feature, index) => (
          <div key={index} className='feature'>
            <span className='feature_svg'>
              {feature === 'Automatic transmission' ? (
                <img src='/assets/svg/automatic.svg' alt='automatic' />
              ) : feature === 'Android Auto' ? (
                <img src='/assets/svg/android.svg' alt='android' />
              ) : feature === 'AUX input' ? (
                <img src='/assets/svg/auxInput.svg' alt='auxInput' />
              ) : feature === 'Backup camera' ? (
                <img src='/assets/svg/camera.svg' alt='camera' />
              ) : feature === 'Bluetooth' ? (
                <img src='/assets/svg/bluetooth.svg' alt='bluetooth' />
              ) : feature === 'GPS' ? (
                <img src='/assets/svg/gps.svg' alt='gps' />
              ) : feature === 'Snow tires or chains' ? (
                <img src='/assets/svg/tires.svg' alt='tires' />
              ) : feature === 'Pet friendly' ? (
                <img src='/assets/svg/pet.svg' alt='pet' />
              ) : feature === 'Long-term car' ? (
                <img src='/assets/svg/long_term.svg' alt='long_term' />
              ) : feature === 'USB charger' ? (
                <img src='/assets/svg/usb.svg' alt='usb' />
              ) : feature === 'USB input' ? (
                <img src='/assets/svg/usbInput.png' alt='usbInput' />
              ) : null}
            </span>
            {feature.name}
          </div>
        ))}
      </div>
      {/* <button className='more_features_btn'>
        Show More Feature
        <svg
          width='6'
          viewBox='0 0 8 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z' />
        </svg>
      </button> */}
    </div>
  );
}
