import React from 'react';
import './index.css';

export default function AboutUs() {
  return (
    <>
      <div></div>
    </>
  );
}
