import { subfix_domain } from '../api';

const get_system_configurations = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/system-configurations/`, {params: { subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_system_configurations,
};
