import { api, subfix_domain } from '../api';

const create_car_booking = async (formData) => {
  try {
    formData.append('subfix_domain', subfix_domain);
    const res = await api.post(`/api/trips/book-car/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_by_id = async (id, start_date = null, end_date = null) => {
  try {
    let res;
    if (start_date && end_date) {
      res = await api.get(`/api/cars/${id}`, {
        params: { subfix_domain, start_date: start_date, end_date: end_date },
      });
    } else {
      res = await api.get(`/api/cars/${id}`, { params: { subfix_domain } });
    }
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_validate_reference = async (car, start_date, end_date) => {
  try {
    const res = await api.post(`/api/trips/car-validate/`, {
      subfix_domain,
      car,
      start_date,
      end_date,
      submitter: 'client',
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_cars = async (filter, is_featured, page) => {
  try {
    const res = await api.get(`/api/cars/`, {
      params: {
        ...filter,
        subfix_domain,
        is_featured: is_featured,
        page: page,
      },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_csrf_token = async (config) => {
  try {
    const res = await api.get(`/api/auth/csrf/`, { params: { subfix_domain } });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_theme = async () => {
  try {
    const res = await api.get(`/api/style/theme/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_airlines = async () => {
  try {
    const res = await api.get(`/api/settings/airlines/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_csrf_token,
  get_all_cars,
  get_car_by_id,
  create_car_booking,
  get_theme,
  get_car_validate_reference,
  get_all_airlines,
};
