import React, { createContext, useEffect, useState } from 'react';
import ThemeService from '../services/ThemeService';
import { API_URL } from '../api';

const DefaultProps = {
  logo: '/assets/imgs/default/logo.png',
  main_image: '/assets/imgs/default/hero.png',
};

export const ThemeContext = createContext(DefaultProps);

export const ThemeContextProvider = ({ children }) => {
  const [themeConfig, setThemeConfig] = useState(
    ThemeService.getCurrentTheme()
  );

  useEffect(() => {
    getTheme();

    async function getTheme() {
      const theme = await ThemeService.getTheme();
      setThemeConfig(theme);
    }
  }, []);

  const { theme, logo, main_image, main_text } = {
    theme: themeConfig?.theme || DefaultProps.theme,
    logo: themeConfig?.logo_image
      ? themeConfig?.logo_image[0]?.image
      : DefaultProps.logo_image,
    main_image: themeConfig?.main_image
      ? themeConfig?.main_image[0]?.image
      : DefaultProps.main_image,
    main_text: themeConfig?.main_text || DefaultProps.main_text
  };

  if (!theme) {
    document.documentElement.style.setProperty('--primary-BG', '#1c2038'); //prettier-ignore
    document.documentElement.style.setProperty('--img-wrap-bg', '#1c2038b0'); //prettier-ignore
    document.documentElement.style.setProperty('--secondary-BG', '#252b48'); //prettier-ignore
    document.documentElement.style.setProperty('--text-white', '#fff'); //prettier-ignore
    document.documentElement.style.setProperty('--text-grey', '#bebebe'); //prettier-ignore
    document.documentElement.style.setProperty('--primary-box-shadow','#14182b'); //prettier-ignore
    document.documentElement.style.setProperty('--secondary-box-shadow','#3c4071'); //prettier-ignore
    document.documentElement.style.setProperty('--primary-border-color','#454d80'); //prettier-ignore
    document.documentElement.style.setProperty('--back-drop', '#252b48cf'); //prettier-ignore
    document.documentElement.style.setProperty('--light-blue', '#4fc8e9'); //prettier-ignore
  } else {
    document.documentElement.style.setProperty('--primary-BG', theme.primary_BG); //prettier-ignore
    document.documentElement.style.setProperty('--img-wrap-bg', theme.img_wrap_bg); //prettier-ignore
    document.documentElement.style.setProperty('--secondary-BG', theme.secondary_BG); //prettier-ignore
    document.documentElement.style.setProperty('--text-white', theme.text_white); //prettier-ignore
    document.documentElement.style.setProperty('--text-grey', theme.text_grey); //prettier-ignore
    document.documentElement.style.setProperty('--primary-box-shadow',theme.primary_box_shadow); //prettier-ignore
    document.documentElement.style.setProperty('--secondary-box-shadow',theme.secondary_box_shadow); //prettier-ignore
    document.documentElement.style.setProperty('--primary-border-color',theme.primary_border_color); //prettier-ignore
    document.documentElement.style.setProperty('--back-drop', theme.back_drop); //prettier-ignore
    document.documentElement.style.setProperty('--light-blue', theme.light_blue); //prettier-ignore
  }

  return (
    <ThemeContext.Provider
      value={{ theme, logo, main_image, main_text }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
