import React, { useContext } from 'react';
import './index.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const themeContext = useContext(ThemeContext);
  const footerData = [
    {
      heading: 'RESERVATIONS',
      body: [
        {
          to: '',
          text: 'Start a Reservation',
        },
        {
          to: '',
          text: 'View/Modify/Cancel',
        },
        {
          to: '',
          text: 'Get a Receipt',
        },
        {
          to: '',
          text: 'Start a Moving Truck or Van Reservation',
        },
        {
          to: '',
          text: 'Short Term Car Rental',
        },
        {
          to: '',
          text: 'Subscribe with Enterprise',
        },
        {
          heading: 'ENTERPRISE PLUS®',
          body: [
            {
              to: '',
              text: 'Sign In',
            },
            {
              to: '',
              text: 'Learn More About Enterprise Plus®',
            },
            {
              to: '',
              text: 'Join Enterprise Plus®',
            },
          ],
        },
      ],
    },
    {
      heading: 'VEHICLES',
      body: [
        {
          to: '',
          text: 'Cars',
        },
        {
          to: '',
          text: 'SUVs',
        },
        {
          to: '',
          text: 'Trucks',
        },
        {
          to: '',
          text: 'Minivans & Vans',
        },
        {
          to: '',
          text: 'Moving Trucks & Vans',
        },
        {
          to: '',
          text: 'Exotic Cars',
        },
        {
          heading: 'SHARE',
          body: [
            {
              to: '',
              text: 'Enterprise CarShare',
            },
            {
              to: '',
              text: 'Commute with Enterprise',
            },
          ],
        },
      ],
    },
    {
      heading: 'PROMOTIONS',
      body: [
        {
          to: '',
          text: 'All Deals & Coupons',
        },
        {
          to: '',
          text: 'Sign Up For Email Specials',
        },
        {
          to: '',
          text: 'Partner Rewards Programs',
        },
        {
          heading: 'BUY',
          body: [
            {
              to: '',
              text: 'Enterprise Car Sales',
            },
          ],
        },
        {
          heading: 'CAR RENTAL LOCATIONS',
          body: [
            {
              to: '',
              text: 'U.S Car Rental Locations',
            },
            {
              to: '',
              text: 'International Car Rental Locations',
            },
            {
              to: '',
              text: 'Airport Car Rental Locations',
            },
          ],
        },
      ],
    },
    {
      heading: 'BUSINESS',
      body: [
        {
          to: '',
          text: 'Solutions for Business',
        },
        {
          to: '',
          text: 'Global Franchise Opportunities',
        },
        {
          to: '',
          text: 'General Aviation',
        },
        {
          to: '',
          text: 'Enterprise Truck Rental',
        },
        {
          to: '',
          text: 'Enterprise Fleet Management',
        },
        {
          to: '',
          text: 'Travel Administrator',
        },
        {
          to: '',
          text: 'Travel Advisor',
        },
        {
          to: '',
          text: 'Enterprise CarShare ',
        },
        {
          to: '',
          text: 'Commute with Enterprise',
        },
        {
          to: '',
          text: 'Entertainment & Production Rentals',
        },
      ],
    },
    {
      heading: 'CUSTOMER SERVICES',
      body: [
        {
          to: '',
          text: 'Help & FAQs',
        },
        {
          to: '',
          text: 'Contact Us',
        },
        {
          to: '',
          text: 'Customers with Disabilities',
        },
        {
          to: '',
          text: 'Site Map',
        },
        {
          to: '',
          text: 'Safety Recall Information',
        },
        {
          heading: 'INSPIRATION',
          body: [
            {
              to: '',
              text: 'Pursuits with Enterprise',
            },
            {
              to: '',
              text: 'Road Trip Ideas',
            },
          ],
        },
      ],
    },
    {
      heading: 'INTERNATIONAL WEBSITES',
      body: [
        {
          to: '',
          text: 'Enterprise Canada',
        },
        {
          to: '',
          text: 'Enterprise France',
        },
        {
          to: '',
          text: 'Enterprise Germany',
        },
        {
          to: '',
          text: 'Enterprise Ireland',
        },
        {
          to: '',
          text: 'Enterprise Spain',
        },
        {
          to: '',
          text: 'Enterprise UK',
        },
        {
          to: '',
          text: 'Other Enterprise Website',
        },
      ],
    },
    {
      heading: 'This Company',
      body: [
        // {
        //   to: '',
        //   text: 'About Us',
        // },
        {
          to: '',
          text: 'Total Mobility Solutions',
        },
        {
          to: '',
          text: 'Meet Our People',
        },
        {
          to: '',
          text: 'Supporting Those in Service',
        },
        {
          to: '',
          text: 'Community & Sustainability ',
        },
        {
          to: '',
          text: 'Enterprise Holdings®',
        },
        {
          to: '',
          text: 'Careers',
        },
        {
          to: '',
          text: 'Press',
        },
        {
          to: '',
          text: 'Affiliate Programs',
        },
        {
          to: '',
          text: 'Youth Sports Fundraising',
        },
      ],
    },
  ];

  const navigate = useNavigate();

  const Instagram = () => {
    return (
      <svg
        viewBox='0 0 64 64'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <defs>
          <radialGradient
            cx='-578.95'
            cy='-837.6'
            gradientTransform='translate(499.5 629.5) scale(0.75)'
            gradientUnits='userSpaceOnUse'
            id='radial-gradient'
            r='197.06'
          >
            <stop offset='0' stop-color='#f9ed32' />
            <stop offset='0.36' stop-color='#ee2a7b' />
            <stop offset='0.44' stop-color='#d22a8a' />
            <stop offset='0.6' stop-color='#8b2ab2' />
            <stop offset='0.83' stop-color='#1b2af0' />
            <stop offset='0.88' stop-color='#002aff' />
          </radialGradient>
        </defs>
        <title />
        <g data-name='3-instagram' id='_3-instagram'>
          <rect
            class='cls-1'
            height='64'
            rx='11.2'
            ry='11.2'
            transform='translate(64 64) rotate(180)'
            width='64'
          />
          <path
            class='cls-2'
            d='M44,56H20A12,12,0,0,1,8,44V20A12,12,0,0,1,20,8H44A12,12,0,0,1,56,20V44A12,12,0,0,1,44,56ZM20,12.8A7.21,7.21,0,0,0,12.8,20V44A7.21,7.21,0,0,0,20,51.2H44A7.21,7.21,0,0,0,51.2,44V20A7.21,7.21,0,0,0,44,12.8Z'
          />
          <path
            class='cls-2'
            d='M32,45.6A13.6,13.6,0,1,1,45.6,32,13.61,13.61,0,0,1,32,45.6Zm0-22.4A8.8,8.8,0,1,0,40.8,32,8.81,8.81,0,0,0,32,23.2Z'
          />
          <circle class='cls-2' cx='45.6' cy='19.2' r='2.4' />
        </g>
      </svg>
    );
  };
  const Faecbook = () => {
    return (
      <svg
        height='512'
        id='Layer_1'
        version='1.1'
        viewBox='0 0 512 512'
        width='512'
        xmlSpace='preserve'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsCc='http://creativecommons.org/ns#'
        xmlnsDc='http://purl.org/dc/elements/1.1/'
        xmlnsInkscape='http://www.inkscape.org/namespaces/inkscape'
        xmlnsRdf='http://www.w3.org/1999/02/22-rdf-syntax-ns#'
        xmlnsSodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd'
        xmlnsSvg='http://www.w3.org/2000/svg'
      >
        <defs id='defs12' />
        <g id='g5991'>
          <rect
            height='512'
            id='rect2987'
            rx='64'
            ry='64'
            style={{
              fill: '#3b5998',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            width='512'
            x='0'
            y='0'
          />
          <path
            d='M 286.96783,455.99972 V 273.53753 h 61.244 l 9.1699,-71.10266 h -70.41246 v -45.39493 c 0,-20.58828 5.72066,-34.61942 35.23496,-34.61942 l 37.6554,-0.0112 V 58.807915 c -6.5097,-0.87381 -28.8571,-2.80794 -54.8675,-2.80794 -54.28803,0 -91.44995,33.14585 -91.44995,93.998125 v 52.43708 h -61.40181 v 71.10266 h 61.40039 v 182.46219 h 73.42707 z'
            id='f_1_'
            style={{ fill: '#ffffff' }}
          />
        </g>
      </svg>
    );
  };
  return (
    <footer className='footer'>
      {/* <div className='footer_heading'>{themeContext?.main_name}</div> */}
      <div className='footer_sections'>
        <div className='footer_logo'>
          <img src={themeContext?.logo ?? '/assets/imgs/logo.png'} alt='logo' />
        </div>
        <div className='footer_sub_section'>
          <div className='footer_section_row'>
            <div className='footer_section_row_item'>About Us</div>
            {/* <div className='footer_section_row_item'>Privacy Policy</div> */}
          </div>
          <div className='footer_section_row'>
            <div
              className='footer_section_row_item'
              onClick={() => navigate('/contact_us')}
            >
              Contact Us
            </div>
            {/* <div className='footer_section_row_item'>Cookie Policy</div> */}
          </div>
          <div className='footer_section_row'>
            <div className='footer_svg'>
              <a
                href='https://instagram.com/renotahoerentalcar?igshid=Mzc1MmZhNjY='
                target='_blank'
                rel='noopener noreferrer'
              >
                <Instagram />
              </a>
              <a
                href='https://www.facebook.com/renotahoerentalcar'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Faecbook />
              </a>
            </div> 
            {/*<div className='footer_section_row_item'>Cookie Setting</div> */}
          </div>
        </div>
      </div>
      <div className='footer_footer'>
        {/* <div className='footer_terms'>
          <div>Terms of Use Privacy Policy</div>
          <div>Cookie Policy</div>
          <div>Cookie Settings / AdChoices </div>
        </div> */}
        <div className='footer_copyright'>
          <div>© 2023 Reno Tahoe Rental All Rights Reserved</div>
        </div>
      </div>
    </footer>
  );
}
